import * as d3 from 'd3';
import { getMaxNumericArrayValue } from '../../services/utils';
import { DIAGRAM_TYPES } from '../Tools/Full/Diagram/DiagramTypes/diagramTypes';
import { getForulaObject, getComputedValue } from '../Tools/Full/SpreadSheet/util';

export const BASE_COLOR_SCHEME = [
    '#1F78C8',
    '#ff0000',
    '#33a02c',
    '#6A33C2',
    '#ff7f00',
    '#565656',
    '#FFD700',
    '#a6cee3',
    '#FB6496',
    '#b2df8a',
    '#CAB2D6',
    '#FDBF6F',
    '#999999',
    '#EEE685',
    '#C8308C',
    '#FF83FA',
    '#C814FA',
    '#0000FF',
    '#36648B',
    '#00E2E5',
    '#00FF00',
    '#778B00',
    '#BEBE00',
    '#8B3B00',
    '#A52A3C'
];

// Function to get a color scheme with the exact number of colors needed
const getColorScheme = (numCols) => {
    const colorScheme = [];
    for (let i = 0; i < numCols; i++) {
        // Cycle through the base color scheme using the modulus operator
        colorScheme.push(BASE_COLOR_SCHEME[i % BASE_COLOR_SCHEME.length]);
    }
    return colorScheme;
};

// Function to process spreadsheet data based on the diagram type
const processSpreadsheetData = (type, spreadsheet) => {
    if (!spreadsheet || !spreadsheet.data) {
        console.error('Spreadsheet data is not available');
        return { xMin: 0, xMax: 0, yMin: 0, yMax: 0 };
    }
    const formulaParser = getForulaObject({ data: spreadsheet });
    const data = [];
    for (let rowIdx = 0; rowIdx < spreadsheet.data.length; rowIdx++) {
        const row = [];
        for (let colIdx = 0; colIdx < spreadsheet.data[rowIdx].length; colIdx++) {
            const cell = spreadsheet.data[rowIdx][colIdx];
            row.push(getComputedValue(cell, formulaParser));
        }
        data.push(row);
    }

    // const data = spreadsheet.data.map(row => row.map(e => e.value));

    let yValsTransposed = data[0].map((col, i) => data.map(row => row[i]));

    let yMin = 0;
    let yMax = 0;
    let xMin = 0;
    let xMax = 0;

    switch (type) {
    case DIAGRAM_TYPES.SCATTERPLOTCHART:
    case DIAGRAM_TYPES.LINECHART:
        xMin = d3.min(yValsTransposed[0].map(x => x === '' ? Number.POSITIVE_INFINITY : Number(x)));
        xMax = d3.max(yValsTransposed[0].map(x => x === '' ? Number.NEGATIVE_INFINITY : Number(x)));
        yValsTransposed = yValsTransposed.slice(1, data[0].length);
        yMin = d3.min(yValsTransposed.flat().map(x => x === '' ? Number.POSITIVE_INFINITY : Number(x)));
        yMax = d3.max(yValsTransposed.flat().map(x => x === '' ? Number.NEGATIVE_INFINITY : Number(x)));

        if (yMin === Number.POSITIVE_INFINITY) yMin = 0;
        if (yMax === Number.NEGATIVE_INFINITY) yMax = 0;

        break;

    case DIAGRAM_TYPES.STACKEDBARCHART:
        data.forEach(row => {
            const rowYMin = d3.sum(row.filter(e => e < 0));
            const rowYMax = d3.sum(row.filter(e => e >= 0));
            if (rowYMin < yMin) yMin = rowYMin;
            if (rowYMax > yMax) yMax = rowYMax;
        });
        break;

    case DIAGRAM_TYPES.GROUPEDBARCHART:
        yMin = d3.min(yValsTransposed.flat().map(Number)) >= 0 ? 0 : d3.min(yValsTransposed.flat().map(Number));
        yMax = getMaxNumericArrayValue(yValsTransposed.flat().map(Number));
        break;

    default:
        break;
    };

    return { xMin, xMax, yMin, yMax };
};

// Function to prepare diagram settings and dispatch necessary actions
export function prepareDiagram (contextManager, boardId, dataId, type, diagramType, diagramSsid, spreadsheet) {
    const { xMin, xMax, yMin, yMax } = processSpreadsheetData(diagramType, spreadsheet);

    const numCols = spreadsheet.data[0].length;

    const colorScheme = getColorScheme(numCols);

    const diagramPredefinedSettings = {
        rows: spreadsheet.data.map((_, idx) => idx),
        cols: spreadsheet.data[0].map((_, idx) => idx),
        categoryColumn: -1,
        xAxes: 0,
        xLabel: 'x-Achse',
        yLabel: 'y-Achse',
        yMin: parseFloat(yMin),
        yMax: parseFloat(yMax),
        xMin: parseFloat(xMin),
        xMax: parseFloat(xMax),
        showXGridLines: true,
        showYGridLines: true,
        legendPosition: 'top-right',
        lineType: 'solid',
        lineThickness: 5,
        pointType: 'circle',
        pointSize: 5,
        opacity: 10,
        barWidth: 10,
        colorScheme: colorScheme
    };

    const diagramSettingsJson = JSON.stringify(diagramPredefinedSettings);

    contextManager.loadComponent(boardId, dataId, type, {
        type: 'diagram',
        value: {
            type: diagramType, // The type of the diagram (e.g., "groupedbarchart")
            ssid: diagramSsid, // The SSID for the diagram
            settings: diagramSettingsJson
        }
    });
}
